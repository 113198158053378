import { ImpactEnum, ImpactValueEnum } from '@mondra/ui-components'

export enum ImpactsDataEnum {
  BIODIVERSITY = 'bio',
  CARBON = 'carbon',
  WATER_POLLUTION = 'waterPollution',
  WATER_USAGE = 'waterUsage',
}

export enum ProductStagesEnum {
  PRODUCT = 'product',
  DISPOSAL = 'disposal',
  FARMING = 'farming',
  INUSE = 'inUse',
  PACKAGING = 'packaging',
  PROCESSING = 'processing',
  SALE = 'sale',
  STORAGE = 'storage',
  TRANSPORT = 'transport',
}

export type ImpactsDataType = Record<ImpactsDataEnum, number>

export interface IImpactTypeDetails {
  bg: string
  icon: string
  id: string
  measure: string
  selected: string
  text: string
  unit: string
  unitTitle: string
  value: string
}

export interface IClassNameProps {
  className?: string
}

export type StringType = string | undefined | null
export type ArrayStringType = string[]

export type DictionaryType = { [key: string]: unknown }

export interface IImpactTypeDefaultLabels {
  title: string
  measure: string
  unit: string
  unitTitle: string
}

export type NullableStringType = string | null
export type NullableNumberType = number | null

export enum CompanyTypeEnum {
  RETAILER = 'Retailer',
  PROCESSOR = 'Processor',
}

export interface ICompany {
  id: string
  zohoCrmAccountId: number
  name: NullableStringType
  type: NullableStringType
  description: NullableStringType
  website: NullableStringType
  companyLogoUrl: NullableStringType
  country: NullableStringType
  state: NullableStringType
  city: NullableStringType
  postCode: NullableStringType
  street: NullableStringType
  registrationNumber: NullableStringType
  isPublic: boolean
  ssoConnection: NullableStringType
  sharePointPath: NullableStringType
  companySize: NullableStringType
  features?: string[]
}

export interface ISourceCompany {
  id: string
  name: string
}

interface ITrendImpactValue {
  value: number
  unit?: string
}

export type TrendImpactsType = Record<ImpactValueEnum, ITrendImpactValue>

export type ImpactsType = Record<ImpactValueEnum, number>
export type MonthlyStatsType = Record<ImpactValueEnum, IEmissionOverTimeData[]>

export interface IStats {
  ingredientCount: number
  per100gAggImpacts: ImpactsType
  per100gAggImpactsTrend: TrendImpactsType
  perAnnualPurchaseQtyImpacts: ImpactsType
  perAnnualSalesQtyImpacts: ImpactsType
  perNetWeightImpacts: ImpactsType
  productCount: number
  supplierCount: number
  title: string
}

export enum SizeEnum {
  XS,
  SM,
  MD,
  LG,
}

export interface IApiParams {
  factor?: TFactors
  pageSize?: number
}

export interface IDashboardApiCommonProps extends IApiParams {
  dimension?: string
  dimensionId?: string
}

export interface IProductDashboardApiProps extends IApiParams {
  dimension?: string
  productId?: string
}

export interface IRecentlyViewedItem {
  id: string
  categoryId: string | null
  type: string
  name: string
  lastViewed: string
}

export interface IEmissionOverTimeData {
  startDate: string
  endDate: string
  productCount: number
  isForecast: boolean
  salesVolume: number
  salesVolumeImpact: number
}

export enum NumberDenominationEnum {
  K = 'K',
  M = 'M',
  B = 'B',
  T = 'T',
}

export interface INumberConversionDetails {
  unit: NumberDenominationEnum
  unitDescription: string
  unitTo: NumberDenominationEnum
  value: number
}

export interface IIntensityScore {
  carbon: number
  waterUsage: number
  waterPollution: number
  bio: number
}

export interface IDataQualityScoreAttributes {
  name: string
  score: number
}

export type DataQualityScoreAttributesType = Record<string, number>

export interface IDataQualityScore {
  score: number
  attributes: IDataQualityScoreAttributes[]
}

export enum DataModelTypeEnum {
  DigitalTwin = 'DigitalTwin',
  DigitalTwinEnhanced = 'DigitalTwinEnhanced',
  ImpactHusk = 'ImpactHusk',
  NullHusk = 'NullHusk',
}

export interface IProductPrimaryDetails {
  averageCarbonIntensityScore?: number
  averageDataQualityScore?: IDataQualityScore
  averagePerKgImpacts?: ImpactsType
  carbonIntensityScore?: number
  customEmissionFactorCount?: number
  dataModelType?: DataModelTypeEnum
  dataQualityScore: IDataQualityScore
  endDate: string
  huskStage: NullableStringType
  huskType?: HuskTypeEnum
  id?: string
  name: string
  netWeight: number
  perKgImpacts: ImpactsDataType
  salesVolume: number
  sku: string
  specificationDate?: string
  specificationId?: string
  specificationVersion?: string
  startDate: string
  supplierName: string
  lastSharedScenario: IScenarioShareDetails | null
}

export interface IEmissionVariationCommonProps {
  dataDimension?: string
  impactType: ImpactEnum
  loading?: boolean
  maxPerKgImpact?: number
  maxSalesVolumeImpact?: number
}

export interface IStatsByIngredientDetailedCommonProps extends IDashboardApiCommonProps {
  saType: string
  code?: string | null
}

export enum SaTypeEnum {
  ComplexIngredient = 'ComplexIngredient',
  Produce = 'Produce',
  Product = 'Product',
  SimpleIngredient = 'SimpleIngredient',
  SpecialIngredient = 'SpecialIngredient',
  ComponentIngredient = 'ComponentIngredient',
}

export enum HuskTypeEnum {
  Owner = 'Owner',
  Coalition = 'Coalition',
}

export enum SupplierInviteType {
  Engaged = 'Engaged',
  Invited = 'Invited',
  Unengaged = 'Unengaged',
}

export interface IInviteSupplier {
  categoriesCount: number
  id: string
  name: string
  productsCount: number
  rejectionReason: StringType
  sitesCount: number
  status: SupplierInviteType
}

export interface IInviteContact {
  email: string
  firstName: string
  lastName: string
  position: string
}

export type InviteStateType = 'Pending' | 'Accepted' | 'Rejected' | 'Expired' | 'Cancelled'

export enum InviteStateEnum {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

export interface IInviteData {
  id?: string
  contact: IInviteContact
  createdBy?: string
  createdDate?: string
  expirationDate?: string
  groupName: string
  rejectionReason: StringType
  status?: InviteStateType
  suppliers: IInviteSupplier[]
  token?: string
}

export interface ISupplierFiltersItem {
  id: string
  name: string
}

export interface IEvent {
  target: {
    id: string
    name: string
    value: string
    checked: boolean
  }
}

export enum GroupTypesEnum {
  MONTH = 1,
  WEEK = 2,
  YEAR = 0,
}

export enum ViewGroupByEnum {
  WEEK = 'week',
  DAY = 'day',
  MONTH = 'month',
}

export const GroupByValues = {
  [GroupTypesEnum.MONTH]: ViewGroupByEnum.WEEK,
  [GroupTypesEnum.WEEK]: ViewGroupByEnum.DAY,
  [GroupTypesEnum.YEAR]: ViewGroupByEnum.MONTH,
}

enum AdditionalFactors {
  DQS = 'dqs_overall',
}
export type TFactors = ImpactEnum | AdditionalFactors
export const Factors = { ...ImpactEnum, ...AdditionalFactors }

export enum AuthErrorTypesEnum {
  no_token = 'no_token',
  login_required = 'login_required',
  consent_required = 'consent_required',
  invalid_grant = 'invalid_grant',
  unauthorized = 'unauthorized',
  timeout = 'timeout',
  invalid_request = 'invalid_request',
  invalid_scope = 'invalid_scope',
  server_error = 'server_error',
  interaction_required = 'interaction_required',
  access_denied = 'access_denied',
}

export enum CompanyErrorTypesEnum {
  NO_COMPANIES = 'No Companies',
  COMPANY_NOT_FOUND = 'Company not found. Please select a company from the company dropdown',
  SOURCE_COMPANY_NOT_FOUND = 'Source company not found',
}

export interface HuskData {
  huskType?: NullableStringType
  huskStage?: NullableStringType
}

export interface IGenerateHuskMessageProps extends HuskData {
  dimension: string
  isNoImpact?: boolean
  productCount?: number
  huskProductCount?: number
  uncertifiedProductCount?: number
}

export enum EFPublishDialogEnum {
  WARNING = 'Warning',
  SUCCESS = 'Success',
  ERROR = 'Error',
}

export enum EFPublishNaviagteEnum {
  ASSIGN_PRODUCTS = 0,
  EF_CREATE = 1,
}

export enum EFStatusEnum {
  APPLIED = 'Applied',
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
}

export interface IOption {
  id: string
  name: string
}

export enum ImpactStatusEnum {
  DECREASE = 'Reduction',
  INCREASE = 'Increase',
  NOCHANGE = 'No change',
  NA = 'N/A',
}

export enum ScenarioStatusEnum {
  Received = 'Received',
  Shared = 'Shared',
  Unshared = 'Unshared',
}

export interface IScenarioShareDetails {
  sharedWithCompanyName?: string
  sharedDate?: string
  sharedBy?: string
  status: ScenarioStatusEnum
  scenarioLinkCode?: string
}

export enum UserClaimsEnum {
  SUBSCRIPTION_TIER = 'https://mondra.com/subscriptionTier',
}
