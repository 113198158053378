/**
 * The below feature keys will be present in
 * the company.features to enable the feature in the application.
 * Before adding the key & values into below, double check with CRM feature
 * keys.
 */

export const COMPANY_FEATURE_FLAGS = {
  AG_DATA: 'AgData',
  AG_DATA_ASSIGNMENT: 'AgDataAssignment',
  CONTRIBUTION_TREE: 'ContributionTree',
  INDUSTRY_AVERAGE: 'IndustryAverage',
  MONDRA_COPILOT: 'MondraCopilot',
  PRODUCTS_EXPORT: 'ProductsExport',
  SCENARIOS: 'Scenarios',
  START_DATE_SETTING: 'SVDataImportStartDate',
  SUPPLIER_INVITATION: 'SupplierInvitation',
}

export const RELEASE_FEATURE_FLAGS = {
  MONDRA_COPILOT_ENABLED: 'MONDRA_COPILOT_ENABLED',
}
export const USER_SUBSCRIPTION_TIERS = {
  INACTIVE: 'Inactive',
}
