import React, { useContext } from 'react'
import AnalysisUrlContextProvider from 'contexts/AnalysisUrlContextProvider'
import SupplierFilterContextProvider from 'contexts/SupplierFilterContextProvider'
import { AppRoutes } from 'pages/AppRoutes'
import { AppFallback } from 'components/SuspenseFallback'
import { PendoTracker } from 'components/PendoTracker'
// import { ErrorPage } from 'pages/error-page/ErrorPage'
import { CompanyContext } from 'contexts/CompanyContextProvider'
import CompanySyncContextProvider from 'contexts/CompanySyncContextProvider'
import SettingsContextProvider from 'contexts/SettingsContextProvider'
import ApolloClientProvider from 'contexts/ApolloClientContextProvider'
import { PageScrollContextProvider } from 'contexts/PageScrollContextProvider'

export function SecuredApp() {
  const { isLoading } = useContext(CompanyContext)

  if (isLoading) {
    return <AppFallback />
  }

  return (
    <CompanySyncContextProvider>
      <SettingsContextProvider>
        <SupplierFilterContextProvider>
          <PendoTracker>
            <AnalysisUrlContextProvider>
              <PageScrollContextProvider>
                <ApolloClientProvider>
                  <AppRoutes />
                </ApolloClientProvider>
              </PageScrollContextProvider>
            </AnalysisUrlContextProvider>
          </PendoTracker>
        </SupplierFilterContextProvider>
      </SettingsContextProvider>
    </CompanySyncContextProvider>
  )
}
