import React, { useContext, useMemo } from 'react'
import { Header, NavTabs } from '@mondra/ui-components'
import { Outlet, useLocation } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import { ANALYSIS_SUB_ROUTES, ROUTE_URLS } from 'constants/routeUrls'
import { COMPANY_FEATURE_FLAGS, RELEASE_FEATURE_FLAGS } from 'constants/featureFlagKeys'
import { UserAvatar } from 'components/user-avatar/UserAvatar'
import { Search } from 'components/search/Search'
import { CopilotChat } from 'components/copilot-chat/Chat'
import { HelpIcon } from 'components/HelpIcon'
import { useCompanyId } from 'hooks/useCompanyId'
import { useSourceCompanyId } from 'hooks/useSourceCompanyId'
import { useCompanyFeatures } from 'hooks/useCompanyFeatures'
import { resolveAppUrl } from 'utils/resolveUrl'
import { UserContext } from 'contexts/UserContextProvider'
import { isFeatureEnabled } from 'utils/isFeatureEnabled'
import { CompanySelectDialog } from './CompanySelectDialog'
import { RetailerSelectDialog } from './RetailerSelectDialog'

const RetailerFilterPaths = [
  ROUTE_URLS.HOME,
  ROUTE_URLS.ANALYSIS,
  ROUTE_URLS.SCENARIOS,
  ROUTE_URLS.ADD_DATA,
]

export default function Layout() {
  const companyId = useCompanyId()
  const { hasFeature } = useCompanyFeatures()
  const { isLoading, isInactiveUser } = useContext(UserContext)

  const { pathname } = useLocation()
  const showRetailerFilter = RetailerFilterPaths.some(p => pathname.indexOf(p) >= 0)

  const sourceCompanyId = useSourceCompanyId()
  const showHeaderNavigation = !isEmpty(companyId) && !isLoading && !isInactiveUser
  const navLinks = useMemo(
    () =>
      showHeaderNavigation
        ? [
            {
              label: 'Home',
              to: resolveAppUrl(ROUTE_URLS.HOME, companyId, sourceCompanyId),
            },
            {
              label: 'Analysis',
              to: resolveAppUrl(
                `${ROUTE_URLS.ANALYSIS}/${ANALYSIS_SUB_ROUTES.HOME}`,
                companyId,
                sourceCompanyId
              ),
            },
            {
              hidden: !hasFeature(COMPANY_FEATURE_FLAGS.SCENARIOS),
              label: 'Scenarios',
              to: resolveAppUrl(ROUTE_URLS.SCENARIOS, companyId, sourceCompanyId),
            },
            {
              hidden: !hasFeature(COMPANY_FEATURE_FLAGS.SUPPLIER_INVITATION),
              label: 'Suppliers',
              to: resolveAppUrl(ROUTE_URLS.ENGAGEMENTS, companyId, sourceCompanyId),
            },
            {
              hidden: !hasFeature(COMPANY_FEATURE_FLAGS.AG_DATA),
              label: 'Add data',
              to: resolveAppUrl(ROUTE_URLS.ADD_DATA, companyId),
            },
          ]
        : [],
    [companyId, sourceCompanyId, hasFeature, showHeaderNavigation]
  )

  return (
    <div className="layout flex min-h-screen flex-col">
      <Header
        className="dark"
        gapClass=""
        left={
          <>
            <CompanySelectDialog />
            {showRetailerFilter && <RetailerSelectDialog />}
          </>
        }
        right={
          <div className="flex h-full items-center justify-between">
            {showHeaderNavigation && <Search />}
            <HelpIcon />
            <UserAvatar />
          </div>
        }
      >
        <div className="w-full border-l border-gray-700">
          <div className="dark flex w-full justify-end space-x-8">
            <NavTabs options={navLinks} gapClass="gap-0" />
          </div>
        </div>
      </Header>
      <div className="flex h-full flex-grow flex-col">
        <Outlet />
        {isFeatureEnabled(RELEASE_FEATURE_FLAGS.MONDRA_COPILOT_ENABLED) &&
          hasFeature(COMPANY_FEATURE_FLAGS.MONDRA_COPILOT) && <CopilotChat />}
      </div>
    </div>
  )
}
