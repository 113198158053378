import React from 'react'
import classNames from 'classnames'
import { Notification } from '@mondra/ui-components'
import Markdown from 'react-markdown'
import rehypeHighlight from 'rehype-highlight'
import remarkGfm from 'remark-gfm'
import { Link } from 'components/Link'
import { ScrollIntoView } from './ScrollIntoView'
import { CopilotLogo } from './CopilotLogo'

export interface IMessage {
  id: string
  text: string
  type: 'user' | 'bot'
  isError?: boolean
}

interface IChatMessagesProps {
  loading: boolean
  messages: IMessage[]
}

const components = {
  a: props => {
    return <Link to={props.href}>{props.children}</Link>
  },
}
export function ChatMessages({ loading, messages }: IChatMessagesProps) {
  return (
    <div className="w-full flex-grow space-y-4 p-4">
      <div className="hidden text-lg font-medium text-brand print:block">
        Mondra Sherpa messages
      </div>
      {messages.map((m, index) => {
        const isBot = m.type === 'bot'
        const isLast = index === messages.length - 1
        return (
          <div key={m.id} className="w-full">
            <div className={classNames('flex', isBot ? 'justify-start space-x-2' : 'justify-end')}>
              {isBot && (
                <div className="flex-shrink-0 py-4">
                  <CopilotLogo className="h-6 w-6" isAnimated={isLast && loading} />
                </div>
              )}
              <div
                className={classNames(
                  'overflow-x-auto rounded-lg p-4 text-sm print:!bg-white',
                  isBot ? 'markdown-body !bg-transparent' : 'max-w-[80%] !bg-coolGray-800'
                )}
              >
                {m.isError ? (
                  <Notification type="error">{m.text}</Notification>
                ) : (
                  <Markdown
                    className="print:!text-primary"
                    components={components}
                    rehypePlugins={[rehypeHighlight, remarkGfm]}
                  >
                    {m.text as string}
                  </Markdown>
                )}
              </div>
            </div>
            {isLast && <ScrollIntoView />}
          </div>
        )
      })}
    </div>
  )
}
