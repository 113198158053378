import React, { createContext, useState, useEffect, useMemo, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { useToken } from 'hooks/useToken'
import { USER_SUBSCRIPTION_TIERS } from 'constants/featureFlagKeys'
import { ROUTE_URLS } from 'constants/routeUrls'
import { UserClaimsEnum } from 'types'
import { CompanyContext } from './CompanyContextProvider'

interface IUserContext {
  isLoading: boolean
  isInactiveUser: boolean
}

const defaultUserContext = {
  isInactiveUser: false,
  isLoading: true,
}

export const UserContext = createContext<IUserContext>(defaultUserContext)

interface IDecodedAccessToken {
  [UserClaimsEnum.SUBSCRIPTION_TIER]: string
}
interface IUserContextProviderProps {
  isAuthenticating: boolean
  children: React.ReactNode
}

export function UserContextProvider({ isAuthenticating, children }: IUserContextProviderProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isInactiveUser, setInactiveUser] = useState<boolean>(false)
  const { companies, isLoading: isCompaniesLoading, company } = useContext(CompanyContext)
  const { acquireToken } = useToken()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUserClaims = async () => {
      try {
        const token = await acquireToken(company?.id)
        const decoded = jwtDecode<IDecodedAccessToken>(token)
        const isInactive =
          decoded[UserClaimsEnum.SUBSCRIPTION_TIER] === USER_SUBSCRIPTION_TIERS.INACTIVE
        setInactiveUser(isInactive)
        setIsLoading(false)
        if (isInactive) {
          navigate(ROUTE_URLS.NEW_ACCOUNT)
        }
      } catch (error) {
        setIsLoading(false)
      }
    }
    if (
      !isAuthenticating &&
      !isCompaniesLoading &&
      ((companies.length > 0 && company?.id) || companies.length === 0)
    ) {
      setIsLoading(true)
      setInactiveUser(false)
      fetchUserClaims()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticating, acquireToken, company?.id, isCompaniesLoading, companies.length])

  const providerValue = useMemo(() => ({ isInactiveUser, isLoading }), [isInactiveUser, isLoading])

  return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>
}
