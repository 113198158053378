import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { LicenseManager } from 'ag-grid-enterprise'
import { Toaster } from '@mondra/ui-components'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { Auth0ProviderWithHistory } from './auth/Authprovider'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import './styles/index.css'

LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENSE}`)

// Start the mocking conditionally.
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENABLE_MOCK) {
  // eslint-disable-next-line
  const { worker } = require('./mocks/browser')
  worker.start()
}

if (process.env.NODE_ENV === 'development') {
  loadDevMessages()
  loadErrorMessages()
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <App />
        <Toaster containerClassName="!z-[10000]" />
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'development') {
  reportWebVitals()
}
