import React, { Suspense, useEffect } from 'react'
import { SWRConfig, SWRConfiguration } from 'swr'
import isEmpty from 'lodash/fp/isEmpty'
import { useAuth0 } from '@auth0/auth0-react'
import CompanyContextProvider from 'contexts/CompanyContextProvider'
import { ErrorPage } from 'pages/error-page/ErrorPage'
import { SecuredApp } from 'pages/SecuredApp'
import { useLoginRedirect } from 'hooks/useLoginRedirect'
import ErrorBoundary from 'components/ErrorBoundary'
import { UserContextProvider } from 'contexts/UserContextProvider'

const swrConfig: SWRConfiguration = { revalidateOnFocus: false, shouldRetryOnError: false }

export function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, error } = useAuth0()
  const triggerLogin = useLoginRedirect()

  useEffect(() => {
    if (!isLoading && !isAuthenticated && isEmpty(error)) {
      triggerLogin()
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, triggerLogin, error])

  if (!isEmpty(error)) {
    return <ErrorPage errorMsg={error instanceof Error ? error.message : error} />
  }

  const isAuthenticating = isLoading || !isAuthenticated

  return (
    <SWRConfig value={swrConfig}>
      <Suspense>
        <ErrorBoundary>
          <CompanyContextProvider isAuthenticating={isAuthenticating}>
            <UserContextProvider isAuthenticating={isAuthenticating}>
              <SecuredApp />
            </UserContextProvider>
          </CompanyContextProvider>
        </ErrorBoundary>
      </Suspense>
    </SWRConfig>
  )
}
