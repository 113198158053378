import React, {
  useState,
  useEffect,
  ReactNode,
  Children,
  cloneElement,
  ReactElement,
  forwardRef,
} from 'react'
import { Button, Tooltip } from '@mondra/ui-components'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { SkeletonLoader } from 'components/SkeletonLoader'
import { IClassNameProps } from 'types'

export interface IBrowseMenuProps extends IClassNameProps {
  direction: number
  children: ReactElement | ReactElement[]
  defaultExpanded?: boolean
  header?: ReactNode
  id?: string
  loading?: boolean
}

interface IBrowseMenuContainer {
  direction: number
  children: ReactNode
}

// Always class must be fully qualified as per tailwindcss
const MENU_OPEN_WIDTH = 'w-[320px]'
const MENU_CLOSE_WIDTH = 'w-14'
const MENU_BG_COLOR = 'bg-coolGray-900'
const MOTION_DATA = {
  center: {
    opacity: 1,
    x: 0,
    zIndex: 1,
  },
  enter: (direction: number) => {
    return {
      opacity: 0,
      x: direction > 0 ? 320 : -320,
    }
  },
  exit: (direction: number) => {
    return {
      opacity: 0,
      x: direction < 0 ? 320 : -320,
      zIndex: 0,
    }
  },
}

const BrowseMenuContainer = forwardRef<HTMLDivElement, IBrowseMenuContainer>(
  ({ direction, children }, ref) => {
    return (
      <motion.div
        ref={ref}
        custom={direction}
        variants={MOTION_DATA}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          opacity: { duration: 0.2 },
          x: { damping: 30, stiffness: 300, type: 'tween' },
        }}
        className={classNames('w-full', MENU_BG_COLOR)}
      >
        {children}
      </motion.div>
    )
  }
)

export function BrowseMenu({
  direction,
  children,
  className,
  defaultExpanded = true,
  header,
  id,
  loading,
}: IBrowseMenuProps) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded)
  useEffect(() => {
    setIsExpanded(defaultExpanded)
  }, [defaultExpanded])

  const handleExpand = () => {
    setIsExpanded(prevExpanded => !prevExpanded)
  }
  return (
    <div
      className={classNames(
        'z-10 max-h-screen-c min-h-screen-c drop-shadow-lg transition-all',
        className,
        isExpanded ? MENU_OPEN_WIDTH : MENU_CLOSE_WIDTH,
        MENU_BG_COLOR
      )}
    >
      <div
        className={classNames(
          isExpanded && 'justify-between gap-x-4 px-6 py-3',
          'flex h-14 items-center justify-center shadow-border-y-px shadow-coolGray-700'
        )}
      >
        {isExpanded && (
          <h3 className="max-w-[232px] flex-1 animate-fade-in truncate text-sm font-normal text-coolGray-200">
            {loading ? (
              <SkeletonLoader className="h-8 w-48" bgColor="bg-coolGray-800" />
            ) : (
              header || 'Explore product categories below'
            )}
          </h3>
        )}
        <Tooltip
          content="Hide menu"
          placement="right"
          className={classNames('!px-4 !py-1', isExpanded ? '' : 'hidden')}
        >
          <Button
            variant="ghost"
            size="sm"
            isOnlyIcon
            iconType={isExpanded ? 'closePanel' : 'treeView'}
            onClick={handleExpand}
          />
        </Tooltip>
      </div>
      <div className="flex w-full overflow-hidden">
        <AnimatePresence custom={direction} mode="popLayout">
          <BrowseMenuContainer key={id} direction={direction}>
            {Children.map(children, (child: ReactElement) =>
              cloneElement(child, {
                isExpanded,
              })
            )}
          </BrowseMenuContainer>
        </AnimatePresence>
      </div>
    </div>
  )
}
