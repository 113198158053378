import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from 'react-use'
import { Button, Dialog, Input } from '@mondra/ui-components'
import { useNavigate } from 'react-router-dom'
import pluralize from 'pluralize'
import { useControls } from 'hooks/useControls'
import { useRecentSearchHistory } from 'hooks/useRecentSearchHistory'
import { useSearchProducts } from 'api/useSearch'
import { SkeletonLoader } from 'components/SkeletonLoader'
import { ANALYSIS_SUB_ROUTES, ROUTE_URLS } from 'constants/routeUrls'
import { ISearchProductResult } from 'api/types'
import { MapDimensionsEncode, QS_ALIAS } from 'constants/queryStrings'
import { mergeAndStringifyQS } from 'utils/queryString'
import { useRecentlyViewed } from 'hooks/useRecentlyViewed'
import { useCompanyId } from 'hooks/useCompanyId'
import { COMPANY_ID_SEARCH_PARAM, Dimensions } from 'constants/'
import { SearchResultItem, SearchResultsLoader, SearchResultsMoreLoader } from './SearchResultItem'

export function Search() {
  const companyId = useCompanyId()
  const { isOpened, open, close } = useControls()
  const navigate = useNavigate()

  const { items: recentSearchItems, updateRecentItems: updateRecentSearches } =
    useRecentSearchHistory()
  const { updateRecentItems } = useRecentlyViewed()

  const [searchText, setSearchtext] = useState<string>('')
  const [debouncedSearchText, setDebouncedSearchText] = useState<string>('')

  useDebounce(
    () => {
      setDebouncedSearchText(searchText)
    },
    750,
    [searchText]
  )

  const inputRef: React.Ref<HTMLInputElement> = useRef(null)

  const {
    data: productsData,
    isLoading: isProductsLoading,
    isLoadingMore: isLoadingMoreProducts,
    hasMore: hasMoreProducts,
    loadMore: loadMoreProducts,
    totalCount: productsTotalCount,
  } = useSearchProducts(debouncedSearchText)

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchtext(value)
  }, [])

  const handleOnClose = useCallback(() => {
    setSearchtext('')
    setDebouncedSearchText('')
    close()
  }, [close])

  useEffect(() => {
    if (isOpened) {
      setTimeout(() => {
        if (inputRef && inputRef.current) {
          inputRef.current.focus()
        }
      })
    }
  }, [isOpened])

  const handleRecentSearchFavClick = useCallback(
    (id: string, isFavourite: boolean) => {
      const item = recentSearchItems.find(recentItem => recentItem.id === id)
      if (item) {
        updateRecentSearches({ ...item, isFavourite })
      }
    },
    [recentSearchItems, updateRecentSearches]
  )

  const handleSearchClick = useCallback(
    (product: ISearchProductResult) => {
      const { categoryId, id, name } = product

      const queryParams: Record<string, string[] | string> = {
        [COMPANY_ID_SEARCH_PARAM]: companyId,
        [QS_ALIAS.DASHBOARD]: [MapDimensionsEncode.ProductCategory, id],
      }

      if (categoryId) {
        queryParams[QS_ALIAS.DASHBOARD] = [MapDimensionsEncode.Product, id]
        queryParams[QS_ALIAS.DIMENSIONS] = MapDimensionsEncode.ProductCategory
        queryParams[QS_ALIAS.DIMENSION_IDS] = categoryId
      }

      updateRecentItems({
        categoryId,
        id,
        lastViewed: new Date().toISOString(),
        name,
        type: Dimensions.Product,
      })

      handleOnClose()
      updateRecentSearches(product)

      navigate(
        `${ROUTE_URLS.ANALYSIS}/${ANALYSIS_SUB_ROUTES.HOME}?${mergeAndStringifyQS('', queryParams)}`
      )
    },
    [companyId, handleOnClose, navigate, updateRecentItems, updateRecentSearches]
  )

  return (
    <div>
      <Button
        iconType="search"
        isOnlyIcon
        size="xl"
        variant="white"
        onClick={open}
        className="p-4.5"
      />

      <Dialog open={isOpened} onClose={handleOnClose} className="sm:mt-20 sm:!align-top">
        <Dialog.Header title="Search products" onClose={handleOnClose} className="!p-3" />
        <Dialog.Description className="flex max-h-[70vh] flex-col gap-y-4 p-3">
          <Input
            ref={inputRef}
            iconType="search"
            iconSize={20}
            autoFocus
            placeholder="Enter product name or SKU"
            value={searchText}
            onChange={handleInputChange}
            className="!h-12 !max-h-12 shrink-0 !rounded !shadow-none"
          />
          {debouncedSearchText ? (
            <>
              {isProductsLoading ? (
                <SkeletonLoader className="h-5 w-40" />
              ) : (
                <div className="flex items-center gap-x-1 text-base font-normal text-primary">
                  {pluralize('Product', productsTotalCount, true)}
                </div>
              )}

              <div className="overflow-auto">
                {isProductsLoading && <SearchResultsLoader />}
                {!isProductsLoading && productsData.length === 0 && (
                  <div>No results. Try changing search criteria</div>
                )}
                {productsData.length > 0 && (
                  <div className="flex w-full flex-col gap-y-2">
                    {productsData.map(product => (
                      <SearchResultItem
                        key={product.id}
                        product={product}
                        highlighText={searchText}
                        onClick={handleSearchClick}
                      />
                    ))}
                  </div>
                )}
                {isLoadingMoreProducts && <SearchResultsMoreLoader />}
                {productsData.length > 0 && hasMoreProducts && !isLoadingMoreProducts && (
                  <div className="flex h-12 items-center justify-center">
                    <Button onClick={loadMoreProducts} size="md" variant="ghost" className="mt-2">
                      Load more
                    </Button>
                  </div>
                )}
              </div>
            </>
          ) : (
            recentSearchItems.length > 0 && (
              <>
                <div className="flex items-center gap-x-1 text-base font-normal text-primary">
                  Last {pluralize('search', recentSearchItems.length, true)}
                </div>
                <div className="overflow-auto">
                  <div className="flex w-full flex-col gap-y-2">
                    {recentSearchItems.map(product => (
                      <SearchResultItem
                        key={product.id}
                        product={product}
                        highlighText={searchText}
                        onClick={handleSearchClick}
                        onFavouriteClick={handleRecentSearchFavClick}
                      />
                    ))}
                  </div>
                </div>
              </>
            )
          )}
        </Dialog.Description>
      </Dialog>
    </div>
  )
}
