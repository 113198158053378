import { useMemo } from 'react'
import { API_URLS } from 'constants/apiUrls'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { useSWRWithToken } from './useSWRWithToken'

interface IScenarioShortCodeInfo {
  companyId: string
  scenarioId: string
  scenarioShortCode: string
  sharedToCompanyId: string
}

interface IScenarioShortUrlProps {
  scenarioShortCode?: string
}

export function useVerifyScenarioShortCode({ scenarioShortCode }: IScenarioShortUrlProps) {
  const url = useMemo(
    () =>
      scenarioShortCode
        ? resolveSwrUrl(API_URLS.SCENARIOS.SHORT_CODE_VERIFY, {
            shortCode: scenarioShortCode,
          })
        : null,
    [scenarioShortCode]
  )
  const { data, error, isLoading } = useSWRWithToken<IScenarioShortCodeInfo>(url)

  return {
    data,
    error,
    isLoading,
  }
}
