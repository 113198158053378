import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, MenuPopover, ConfirmDialog, List } from '@mondra/ui-components'
import { useAuth0 } from '@auth0/auth0-react'
import { format, parseISO } from 'date-fns'
import { useControls } from 'hooks/useControls'
import { useCompanyFeatures } from 'hooks/useCompanyFeatures'
import { CompanySyncContext } from 'contexts/CompanySyncContextProvider'
import { COMPANY_FEATURE_FLAGS } from 'constants/featureFlagKeys'
import { NOT_AVAILABLE } from 'constants/'

const versionList = [
  {
    id: 'app',
    label: 'App Name',
    value: 'Mondra SaaS',
  },
  {
    id: 'ui',
    label: 'UI',
    value: process.env.REACT_APP_UI_VERSION,
  },
  {
    id: 'api',
    label: 'API',
    value: process.env.REACT_APP_API_VERSION,
  },
]
export function UserAvatar() {
  const { isOpened, open, close } = useControls()
  const { hasFeature } = useCompanyFeatures()
  const { logout, user } = useAuth0()
  const { lastSyncDate } = useContext(CompanySyncContext)
  const navigate = useNavigate()

  const options = useMemo(() => {
    return [
      { id: 'about', onClick: open, value: 'About' },
      {
        hidden: !hasFeature(COMPANY_FEATURE_FLAGS.START_DATE_SETTING),
        id: 'settings',
        onClick: () => {
          navigate('/settings')
        },
        value: 'Settings',
      },
      {
        id: 'logout',
        onClick: () => {
          logout({ returnTo: window.location.origin })
        },
        value: 'Logout',
      },
    ]
  }, [logout, navigate, open, hasFeature])

  const syncDataList = useMemo(() => {
    return [
      {
        id: 'lastSyncDate',
        label: 'Last sync date',
        value: lastSyncDate ? format(parseISO(lastSyncDate), 'MM/dd/yyyy H:mm') : '',
      },
    ]
  }, [lastSyncDate])

  const userName = useMemo(() => {
    if (user?.given_name && user?.family_name) {
      return `${user?.given_name} ${user?.family_name}`
    } else {
      return `${user?.given_name || user?.family_name || user?.email || NOT_AVAILABLE}`
    }
  }, [user?.given_name, user?.family_name, user?.email])

  return (
    <div className="text-sm font-medium">
      <MenuPopover
        triggerButtonClass="h-14 w-14 hover:bg-graySecondary-900"
        showBorder
        menuItems={options}
        trigger={
          <div className="flex items-center">
            <Avatar
              name={userName}
              size="xs"
              bgColor="bg-cyan-600"
              textColor="text-white"
              singleChar
            />
          </div>
        }
      />
      <ConfirmDialog
        open={isOpened}
        onClose={close}
        title="About this application"
        primaryBtnText="Ok"
        onPrimaryBtnClick={close}
        hideSecondaryBtn
      >
        <List className="my-4 rounded border border-gray-300" items={versionList} />
        <h6 className="font-medium text-primary">Company sync data</h6>
        <List className="my-4 rounded border border-gray-300" items={syncDataList} />
      </ConfirmDialog>
    </div>
  )
}
