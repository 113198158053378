import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { CompanyErrorTypesEnum, ICompany, ISourceCompany } from 'types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useCompanies } from 'api/useCompanies'
import { COMPANY_ID_SEARCH_PARAM, EMPTY_COMPANY, SOURCE_COMPANY_ID_SEARCH_PARAM } from 'constants/'
import { useSourceCompanies } from 'api/useSourceCompanies'
import { ROUTE_URLS } from 'constants/routeUrls'
import { resolveAppUrl } from 'utils/resolveUrl'

export interface ICompanyContextProps {
  companies: ICompany[]
  company: ICompany
  error: Error | undefined
  isLoading: boolean
  setCompany: (company: any) => void
  setSourceCompanyId: (id: string) => void
  sourceCompanies: ISourceCompany[]
  sourceCompany?: ISourceCompany
}

export const defaultContextProps = {
  companies: [],
  company: EMPTY_COMPANY,
  error: undefined,
  isLoading: true,
  setCompany: () => {},
  setSourceCompanyId: () => {},
  sourceCompanies: [],
  sourceCompany: undefined,
}

export const CompanyContext = createContext<ICompanyContextProps>(defaultContextProps)
interface ICompanyContextProviderProps {
  isAuthenticating: boolean
  children: React.ReactNode
}

export default function CompanyContextProvider({
  children,
  isAuthenticating,
}: ICompanyContextProviderProps) {
  const [company, setCompany] = useState<ICompany>(EMPTY_COMPANY)
  const [sourceCompany, setSourceCompany] = useState<ISourceCompany>()
  const [error, setError] = useState<Error | undefined>()
  const { companies, isLoading } = useCompanies()
  const { data: sourceCompanies, isLoading: isScLoading } = useSourceCompanies(company?.id)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const sourceCompanyId = useMemo(
    () => searchParams.get(SOURCE_COMPANY_ID_SEARCH_PARAM),
    [searchParams]
  )

  const setSourceCompanyId = useCallback(
    (id: string) => {
      if (sourceCompanyId !== id) {
        setSearchParams({ [SOURCE_COMPANY_ID_SEARCH_PARAM]: id })
      }
    },
    [setSearchParams, sourceCompanyId]
  )

  const setSearchParamId = useCallback(
    (urlKey: string, id: string) => {
      setSearchParams(params => {
        if (!params.get(urlKey)) {
          params.set(urlKey, id)
        }
        return params
      })
    },
    [setSearchParams]
  )

  const isSearchParamMissing = useCallback(
    (urlKey: string, id?: string) => {
      return !searchParams.get(urlKey) && id
    },
    [searchParams]
  )

  const isSearchParamIdMismatch = useCallback(
    (urlKey: string, id?: string) => {
      return searchParams.get(urlKey) !== id
    },
    [searchParams]
  )

  const companyId = useMemo(() => searchParams.get(COMPANY_ID_SEARCH_PARAM), [searchParams])
  useEffect(() => {
    if (isAuthenticating || isLoading || error) return
    if (isSearchParamMissing(COMPANY_ID_SEARCH_PARAM, company.id)) {
      setSearchParamId(COMPANY_ID_SEARCH_PARAM, company.id)
      return
    }

    if (!companyId) {
      setCompany(companies[0] || EMPTY_COMPANY)
      if (companies[0]?.id) {
        setSearchParamId(COMPANY_ID_SEARCH_PARAM, companies[0]?.id)
      }
      if (companies?.length === 0) {
        setError(new Error(CompanyErrorTypesEnum.NO_COMPANIES))
        navigate(ROUTE_URLS.ERROR)
      }
    } else if (isSearchParamIdMismatch(COMPANY_ID_SEARCH_PARAM, company.id)) {
      const newCompany = companies.find(c => c.id === companyId)

      if (newCompany) {
        setCompany(newCompany)
      } else if (companies.length > 0) {
        setError(new Error(CompanyErrorTypesEnum.COMPANY_NOT_FOUND))
        navigate(resolveAppUrl(ROUTE_URLS.ERROR, companyId))
      }
    }
  }, [
    companies,
    company.id,
    companyId,
    error,
    isAuthenticating,
    isLoading,
    isSearchParamIdMismatch,
    isSearchParamMissing,
    navigate,
    setSearchParamId,
  ])

  useEffect(() => {
    if (companyId !== company?.id) {
      setError(undefined)
    }
  }, [company?.id, companyId])

  /* source company logic */
  useEffect(() => {
    if (isScLoading || error || !company.id) {
      return
    }

    if (sourceCompanies.length === 0) {
      if (sourceCompanyId) {
        setSearchParams(params => {
          params.delete(SOURCE_COMPANY_ID_SEARCH_PARAM)
          return params
        })
      }
      setSourceCompany(undefined)
      return
    }

    if (
      sourceCompany &&
      sourceCompany.id &&
      isSearchParamMissing(SOURCE_COMPANY_ID_SEARCH_PARAM, sourceCompany.id)
    ) {
      setSearchParamId(SOURCE_COMPANY_ID_SEARCH_PARAM, sourceCompany.id)
      return
    }

    const found = sourceCompanies.find(c => c.id === sourceCompanyId)

    if (!sourceCompanyId || !found) {
      setSourceCompany(sourceCompanies[0])
      setSearchParamId(SOURCE_COMPANY_ID_SEARCH_PARAM, sourceCompanies[0].id)
    } else if (isSearchParamIdMismatch(SOURCE_COMPANY_ID_SEARCH_PARAM, sourceCompany?.id)) {
      if (found) {
        setSourceCompany(found)
      } else {
        setError(new Error('Source company does not exist'))
      }
    }
  }, [
    company?.id,
    error,
    isScLoading,
    isSearchParamIdMismatch,
    isSearchParamMissing,
    setSearchParamId,
    setSearchParams,
    sourceCompanies,
    sourceCompany,
    sourceCompanyId,
  ])

  const providerValue = useMemo(
    () => ({
      companies,
      company,
      error,
      isLoading: isLoading || isAuthenticating || isScLoading,
      setCompany,
      setSourceCompanyId,
      sourceCompanies,
      sourceCompany,
    }),
    [
      companies,
      company,
      error,
      isAuthenticating,
      isLoading,
      isScLoading,
      setSourceCompanyId,
      sourceCompanies,
      sourceCompany,
    ]
  )

  return <CompanyContext.Provider value={providerValue}>{children}</CompanyContext.Provider>
}
