import React, { useContext } from 'react'
import { Button, Notification, Link } from '@mondra/ui-components'
import { useLocation, useSearchParams } from 'react-router-dom'
import { AUTH_ERRORS } from 'constants/authErrors'
import { useLogout } from 'hooks/useLogout'
import { GET_SUPPORT } from 'constants/helpContent'
import { CompanyContext } from 'contexts/CompanyContextProvider'

interface IErrorPageProps {
  errorCode?: string
  errorMsg?: string
}

export function ErrorPage({ errorCode, errorMsg }: IErrorPageProps) {
  const location = useLocation()
  const triggerLogout = useLogout()
  const { errorDescription } = location.state || {}
  const [searchParams] = useSearchParams()
  const errorKey = searchParams.get('errorKey') || errorCode
  const { error } = useContext(CompanyContext)

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex max-w-2xl flex-col">
        <div className="mb-4 text-[72px] font-medium text-primary">{errorKey ? 'Error' : 401}</div>
        <div className="flex w-full justify-between pb-9">
          <div className="flex max-w-md flex-col justify-center border-r pr-8 border-primary">
            <p className="text-2xl font-medium text-coolGray-600">
              We can&rsquo;t seem to find the page you&rsquo;re looking for.
            </p>
            <p className="pt-4 text-sm font-normal leading-5 text-black">
              The page you are looking for may no longer be available, or an error may have
              occurred.
            </p>
          </div>
          <div className="ml-4 flex flex-col items-center justify-center space-y-4">
            <Link as="a" appearance="button" variant="primary" to={GET_SUPPORT} target="_blank">
              Get Support
            </Link>
            <Button
              variant="ghost"
              className="text-sm font-medium leading-4"
              onClick={triggerLogout}
            >
              Try Login again
            </Button>
          </div>
        </div>
        <Notification
          className=""
          type="error"
          label={errorKey ? AUTH_ERRORS[errorKey] : 'Auth Error - 401'}
        >
          {errorDescription || errorMsg || error?.message}
        </Notification>
      </div>
    </div>
  )
}
