import React from 'react'
import { LinkProps, Link as RouterLink } from 'react-router-dom'
import classNames from 'classnames'

interface ILinkProps extends LinkProps {
  children: React.ReactNode
}

export function Link({ children, className, ...linkProps }: ILinkProps) {
  return (
    <RouterLink
      {...linkProps}
      className={classNames(
        'text-sm font-medium leading-4 hover:underline hover:text-brand active:underline active:text-brand',
        className
      )}
    >
      {children}
    </RouterLink>
  )
}
