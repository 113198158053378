import { useAuth0 } from '@auth0/auth0-react'
import { SAAS_APP } from 'constants/'

export function useLoginRedirect() {
  const { loginWithRedirect } = useAuth0()
  function triggerLogin(defaultUrl?: string) {
    loginWithRedirect({
      appState: {
        appQueryParams: window.location.search,
        returnTo: `${defaultUrl || window.location.pathname}`,
      },
      mondra_app: SAAS_APP,
    })
  }
  return triggerLogin
}
