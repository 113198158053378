import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mondra/ui-components'
import { ROUTE_URLS } from 'constants/routeUrls'
import { GET_SUPPORT } from 'constants/helpContent'

export function PageNotFound() {
  const navigate = useNavigate()
  const handleGoToHome = () => {
    navigate(ROUTE_URLS.HOME)
  }
  return (
    <div className="flex h-screen flex-col items-center pt-24">
      <div className="flex space-x-4 divide-x-2 divide-primary-600">
        <h2 className="text-2xl font-medium text-primary-500">404</h2>
        <div className="pl-4">
          <p className="text-3xl font-semibold text-primary">Uh oh! I think you&rsquo;re lost.</p>
          <p className="text-sm font-normal text-coolGray-700">
            It looks like the page you are looking for doesn&rsquo;t exist
          </p>
          <Button variant="secondary" onClick={handleGoToHome} className="mt-6">
            Go back home
          </Button>
          <a href={GET_SUPPORT} target="_blank" rel="noopener noreferrer">
            <Button variant="primary" className="ml-4 mt-6">
              Contact
            </Button>
          </a>
        </div>
      </div>
    </div>
  )
}
